<template>
  <div>
    <component
      :is="layout.template"
      v-for="(layout, index) in layouts"
      :key="layout.id"
      :layout="layout"
      :index="index"
    ></component>
  </div>
</template>

<script>
import ConciseRight from '@/layouts/ConciseRight';
import ConciseLeft from '@/layouts/ConciseLeft';
import ConciseLeftLogo from '@/layouts/ConciseLeftLogo';

export default {
  data: function () {
    return {};
  },
  computed: {
    layouts() {
      return this.$store.getters.getLayouts('Retail Info');
    },
  },
  metaInfo() {
    return {
      title: "Hardware Rewards Warehouse Membership | Page Custom Supply",
      meta: [
        {
          name: 'description',
          content: 'Click here today to learn more about Page Custom Supply’s Hardware Warehouse Membership benefits and unlock better pricing and special offers!'
        },
        { name: 'keywords', content: 'hardware rewards, hardware supply' }
      ]
    }
  },
  watch: {},
  mounted() {},
  methods: {},
  components: {
    ConciseRight,
    ConciseLeft,
    ConciseLeftLogo,
  },
};
</script>
<style scoped></style>
